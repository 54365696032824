<template>
 <!-- Start HeaderInside -->
<HeaderInside/>
<!-- End HeaderInside -->
<div class="portada team">
<main id="request-demo" class="content-inside-main">
                <div  class="section-title color-blue">
                  

                     <div class="container">
          <div class="row">
          
            <div class="col-xl-6 team_img">
              <img src="@/assets/img/EdgarChavez.jpeg"  width="500" height="600" alt="" />   
            </div>
             <div class="col-xl-6 team_txt" style="background: rgba(255,255,255,0.7);">
                <br>
                  <h1>{{ $t("member_edgar_title") }}</h1>                  
                  <p style="color:black">{{ $t("member_edgar_text1") }}</p>                 
                 <br>
                  <h4>{{ $t("member_edgar_text2") }}</h4>
                  <p> <a href="tel:+3236970699" target="_blank">(323) 697 0699</a> </p>
                  <p style="color:black">{{ $t("member_edgar_text4") }}</p>           
                  <p>
                    <a href="mailto:helpcs@mytpmschool.com" target="_blank">helpcs@mytpmschool.com</a>
                  </p>                
                  <p>
                    <a href="https://cs.mytpmschool.com" target="_blank">cs.mytpmschool.com</a>
                  </p>                 
                  <p> <a href="tel:+5623998088" target="_blank">(562)399-8088</a> </p>                                              
            </div>
          </div>
        </div>
                    

                
                </div>

                 
                
           

        </main><!-- End #main -->

</div>
    <!-- Start Footer -->
    <Footer/>   
    <!-- End Footer -->
</template>
<script>
import HeaderInside from "@/components/common/HeaderInside.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  props: ["data"],
  data() {
    return {
      scrollPosition: null,
      screenSize: null,
    };
  },
  name: "Home",
  components: {
    HeaderInside,
    Footer,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getDimensions() {
      this.screenSize = document.documentElement.clientWidth;
    },
  },
  mounted() {
      this.screenSize = document.documentElement.clientWidth;
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.getDimensions);
    
  },
};
</script>

<style>
@import "@/assets/css/style.css";
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




</style>